import firebase from 'firebase'
import firebaseConfig from '../firebase/config'
firebase.initializeApp(firebaseConfig)

require("firebase/functions")

export const createSetupIntentFn = (data) => {
    //console.log('call')
    var createSetupIntent = firebase.functions().httpsCallable('createSetupIntent');
    return createSetupIntent(data)
}