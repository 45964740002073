import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import CreateToken from './components/CreateToken/CreateToken'

function App() {
  return (
    <BrowserRouter>
      <Route path='/token/create/:stripeCustomerId' component={CreateToken} />
    </BrowserRouter>
  );
}

export default App;
