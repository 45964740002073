import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import {
    CardElement,
    Elements,
    useElements,
    useStripe
} from '@stripe/react-stripe-js';
import ScaleLoader from 'react-spinners/ScaleLoader'

import { createSetupIntentFn } from '../../https/https'


// UPDATE THIS - using a test key
const stripePromise = loadStripe('pk_live_zyLYpZGrsNtwHyK4zTky1Ddx00l3Wbk3e5');

const CardForm = props => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false)
    const stripe = useStripe();
    const elements = useElements();

    const CARD_ELEMENT_OPTIONS = {
        disabled: loading,
        style: {
            base: {
                color: '#32325d',
                fontFamily: 'Roboto',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        }
    };
    
    let { stripeCustomerId } = props

    // Handle real-time validation errors from the card Element.
    // event message is (loading, formOutcome, changeFlag)
    const handleChange = (event) => {
        window.ReactNativeWebView.postMessage('false,null,true');
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    // Handle form submission.
    const handleSubmit = async (event) => {
        setLoading(true)
        window.ReactNativeWebView.postMessage('true,null,true,null,null');
        //message keys:
            // 0: loading
            // 1: stripe response
            // 2: data changed flag - used to enable the submit button in app
            // 3: setupIntent status
            // 4: setupIntent next_action
            // 5: setupIntent Id
        event.preventDefault();
        const card = elements.getElement(CardElement);

        const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        });
        if (error) {
            // Inform the user if there was an error.
            setError(error.message);
            setLoading(false)
            window.ReactNativeWebView.postMessage('false,error,true');
        } else {
            setError(null);
            // Send the token to your server.
            createSetupIntentFn({ stripeCustomerId, paymentMethod })
                .then(res => {
                    let setupIntentStatus = res.data.setupIntentStatus
                    let nextActionUrl = setupIntentStatus === 'requires_action' ? res.data.setupIntentNextAction.use_stripe_sdk.stripe_js : null
                    let setupIntentId = res.data.setupIntentId
                    setLoading(false)
                    window.ReactNativeWebView.postMessage(`false,success,true,${setupIntentStatus},${nextActionUrl},${setupIntentId}`);
                })
                .catch(err => {
                    setError(err.message)
                    setLoading(false)
                    window.ReactNativeWebView.postMessage('false,error,true,null,null');
                })
        }
    };

    return (
        <form
            id='cardform' onSubmit={handleSubmit}>
            <CardElement
                id="card-element"
                options={CARD_ELEMENT_OPTIONS}
                onChange={handleChange}
            />
            <div style={{
                fontFamily: 'Roboto',
                fontSize: 14,
                color: 'red',
                marginTop: 5
            }}>{error}</div>
            <button
                id="submit-button"
                disabled={loading}
                type="submit"
                style={{
                    fontFamily: 'Roboto',
                    fontSize: 14,
                    height: 50,
                    backgroundColor: loading ? 'gray' : 'green',
                    color: 'white',
                    borderWidth: 0,
                    borderColor: 'white',
                    padding: 10,
                    marginTop: 25,
                    width: '100%',
                    visibility: 'hidden'
                }}>{loading ? <ScaleLoader color='white' height={20} width={5} margin={2} /> : 'Save payment method'}</button>
        </form>
    );
}

const CreateToken = props => {
    let { stripeCustomerId } = props.match.params

    return (
        <Elements
            stripe={stripePromise}
            options={{
                fonts: [
                    {
                        cssSrc:
                            "https://fonts.googleapis.com/css?family=Roboto"
                    }
                ]
            }}
        >
            <CardForm stripeCustomerId={stripeCustomerId} />
        </Elements>
    );
}

export default CreateToken